
.accordion {

  .card {
    background-color: #fff;
    border: 1px solid $color-divider-light !important;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .card-title {
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    background-color: $color-bg-lighter;
  }

  .card-title a {
    display: block;
    padding: 16px 33px;
    color: $color-title;

    &::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      border-left: 1px solid $color-text;
      border-top: 1px solid $color-text;
      margin-right: 20px;
      margin-left: -33px;
      margin-bottom: 3px;
      transform: rotate(45deg);
      transition: .2s linear;
    }

    &.collapsed::before {
      transform: rotate(-135deg);
    }
  }

}


//----------------------------------------------------/
// Connected
//----------------------------------------------------/
.accordion-connected {
  border: 1px solid $color-divider-light;

  .card {
    margin-bottom: 0;
    border: 0 !important;
    border-bottom: 1px solid $color-divider-light !important;

    &:last-child {
      border-bottom: 0 !important;
    }
  }


  .card-title {
    border-bottom: 0;

    a {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}


//----------------------------------------------------/
// Light
//----------------------------------------------------/
.accordion-light {
  .card-title {
    background-color: #fff;
  }
}


//----------------------------------------------------/
// Flash right
//----------------------------------------------------/
.accordion-arrow-right {

  .card-title a {
    padding-left: 0;
    padding-right: 0;

    &::before {
      float: right;
      margin-left: 0;
      margin-right: 0;
      margin-top: 10px;
    }
  }
}
