
// Button sizes
@mixin button-size($padding-top, $padding-bottom, $padding-x, $font-size) {
  padding: $padding-top $padding-x $padding-bottom;
  font-size: $font-size;
}


// Circle button sizes
//
@mixin circle-button-size($size) {
  width: $size;
  height: $size;
}
